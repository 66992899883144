@media (max-width: 768px){
    .rec.rec.carousel{

    }
    #carousel-product .rec.rec-item-wrapper{
        height: 260px;
    }
}


.rec.rec-arrow {
    background-color: white;
    border: 0;
    box-shadow: none;
    color: black;
}

.rec.rec-arrow:hover {
    background-color: white;
    border: 0;
    box-shadow: none;
    color: black;
    opacity: 1;
}

.rec.rec-arrow:enabled {
    background-color: white;
    border: 0;
    box-shadow: none;
    color: black;
    opacity: 1;
}

.rec.rec-arrow:hover:enabled {
    background-color: white;
    border: 0;
    box-shadow: none;
    color: black;
    opacity: 1;
}

.rec.rec-arrow:focus:enabled {
    background-color: white;
    border: 0;
    box-shadow: none;
    color: black;
    opacity: 1;
}

.rec.rec-arrow:disabled {
    background-color: white;
    border: 0;
    box-shadow: none;
    color: black;
    opacity: 0.3;
}
